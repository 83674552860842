import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const createDevice = createAsyncThunk(
  // '@@types/create-type',
  // (type) => $host.post('api/type', type)
  "@@devices/create-device",
  async (device, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/device", device);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
// метод для нахождения одного товара
export const loadDeviceByUrl = createAsyncThunk(
  "@@devices/load-device-by-url",
  async (url, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/device/${url}`);
      // console.log(response, "ответ по устройству");
      return response;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadDevices = createAsyncThunk("@@devices/load-devices", () =>
  $host.get("api/device")
);
export const updateOrderss = createAsyncThunk("@@devices/update-devices", () =>
  $host.get("api/device/order/updateorder")
);
export const loadDevicesToType = createAsyncThunk(
  "@@devices/load-devices-to-type",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/device/bytype/${id}`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const loadDevicesToTypeAdmin = createAsyncThunk(
  "@@devices/load-devices-to-type",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/device/bytypeadmin/${id}`);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const delDevice = createAsyncThunk(
  "@@devices/delete-devices",
  async (id) => {
    const res = await $host.delete(`api/device/${id}`);
    return res.data;
  }
);
export const upDevice = createAsyncThunk("@@devices/up", async (id) => {
  const res = await $host.post(`api/device/up/${id}`);
  return res.data;
});
export const downDevice = createAsyncThunk("@@devices/down", async (id) => {
  const res = await $host.post(`api/device/down/${id}`);
  return res.data;
});

// export const editDevice = createAsyncThunk(
//     '@@devices/edit-devices',
//     async ({ id, name }) => {
//         const res = await $host.patch(`api/device/${id}`, { name })
//         return res.data
//     }
// );

export const editDevice = createAsyncThunk(
  "@@devices/edit-device",
  async ({ id, device }, { rejectWithValue }) => {
    try {
      const response = await $host.patch(`api/device/${id}`, device);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

// () => {
//     const { data } = await $host.get('api/type')
//     return data
// }
