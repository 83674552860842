import { createSlice } from "@reduxjs/toolkit";

// action/reducer/dispatch('forward')
const initialState = {
  status: "idle",
  items: [], //{name, price, quantity}
};
const deliveryPrice = (state) => state.delivery.price;

const cartSlice = createSlice({
  name: "@@cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const item = state.items.find(
        (item) =>
          item.id === action.payload.id && item.price === action.payload.price
      );
      if (item) {
        item.quantity = item.quantity + 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },

    removeItemFromCart: (state, action) => {
      const item = state.items.find((item) => item.id === action.payload.id);
      if (item) {
        if (item.quantity > 1) {
          item.quantity = item.quantity - 1;
        } else {
          // Удалить элемент из корзины, если количество становится равно 0
          state.items = state.items.filter((i) => i.id !== action.payload.id);
        }
      }
    },
    deleteItemFromCart: (state, action) => {
      state.items = state.items.filter(
        (item) =>
          !(
            (item.id === action.payload.id)
            // && item.price === action.payload.price
          )
      );
    },
    resetCart: (state) => {
      state.items = [];
    },
    // toggleDeliveryInCart: (state, action) => {
    //   const { payload: deliveryOption } = action;
    //   // const { deliveryPrice } = state;

    //   if (deliveryOption === "Доставка" && deliveryPrice !== "") {
    //     const deliveryItemIndex = state.items.findIndex(
    //       (item) => item.name === "Доставка"
    //     );
    //     if (deliveryItemIndex !== -1) {
    //       state.items[deliveryItemIndex].price = deliveryPrice;
    //     } else {
    //       state.items.push({
    //         id: "delivery",
    //         name: "Доставка",
    //         price: deliveryPrice,
    //         quantity: 1,
    //         dimensions: { qnt: 1, height: 1 },
    //       });
    //     }
    //   } else {
    //     const deliveryItemIndex = state.items.findIndex(
    //       (item) => item.name === "Доставка"
    //     );
    //     if (deliveryItemIndex !== -1) {
    //       state.items.splice(deliveryItemIndex, 1);
    //     }
    //   }
    // },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(loadDevices.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })

  // },
});

export const {
  addItemToCart,
  removeItemFromCart,
  deleteItemFromCart,
  resetCart,
  // toggleDeliveryInCart,
} = cartSlice.actions;
export const selectQntByItem = (state, id, price) => {
  const item = state.cart.items.find(
    (item) => item.id === id
    // && item.price === price
  );
  return item ? item.quantity : 0;
};
export const selectAllItems = (state) => {
  return state.cart.items;
};
export const selectTotalPrice = (state) => {
  return state.cart.items.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.price * currentValue.quantity,
    0
  );
};
export const selectTotalQnt = (state) => {
  return state.cart.items.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  );
};

export const cartReducer = cartSlice.reducer;
