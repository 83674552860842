import styles from "./header.module.scss";
import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import { BsTelephone } from "react-icons/bs";
// import logo from "../../../static/images/logo/evagreen.jpg";

import { useSelector } from "react-redux";
import { selectTotalPrice } from "../../../app/redux/cartStore/cartSlice";
import CartPopup from "../../../pages/cart/CartPopup";
import logo from "../../../static/logo.png";
import City from "../../../widgets/cities/City";
import { Link } from "react-router-dom";
import BurgerMenu from "../burgermenu/BurgerMenu";
import { IoIosHeartEmpty } from "react-icons/io";
import { TfiSearch } from "react-icons/tfi";
import DeliveryTimeComponent from "../../../features/Delivery/DeliveryTimeComponent";
import WatsApp from "../../../widgets/watsapp/WatsApp";

// исправить семантику

const Header = () => {
  //--------бургер меню--------------
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Изменяем состояние при клике на кнопку
  };
  const closeMenu = () => {
    // Функция для закрытия меню
    setIsMenuOpen(false);
  };

  //----------/бургер ----------

  // <BsTelephone />
  return (
    <>
      <div
        className={isMenuOpen ? `${styles.active}` : `${styles.wrapper}`}
        // className={styles.wrapper}
        onClick={() => setIsMenuOpen(false)}>
        <BurgerMenu isActive={isMenuOpen} close={closeMenu} />
      </div>
      <header
        className={styles.main}
        // onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.delivery}>
          {/* <DeliveryTimeComponent /> */}
          <p className={styles.headTop}>
            {" "}
            цветы по самым низким ценам с доставкой по Саратову и Энгельсу
          </p>
        </div>
        <div className={styles.top}>
          <div className={styles.burgerButton} onClick={toggleMenu}>
            {isMenuOpen ? (
              // Если меню открыто, показываем крестик
              <div className={styles.closeIcon}></div>
            ) : (
              // Если меню закрыто, показываем бургер иконку
              <div className={styles.burgerIcon}></div>
            )}
          </div>

          <div className={styles.top__logo}>
            {/* <Link to="/" className={styles.logo}>
              <img src={logo} className={styles.logoimg} alt="логотип" />
            </Link> */}

            <Link to="/" className={styles.logotext}>
              <div className={styles.logop}>CVETUT</div>
            </Link>

            {/* <div
              // onClick={() => setIsMenuOpen(false)}
              className={styles.cityWrapper}>
              <City />
            </div> */}
            {/* <div className={styles.logoWrap}> */}

            {/* </div> */}
          </div>
          <div className={styles.navbar}>
            {/* <div className={styles.navigation}> */}
            <Navbar />
          </div>
          <div className={styles.svgarray}>
            {/* <WatsApp /> */}
            <CartPopup />
            <TfiSearch className={styles.search} />

            <IoIosHeartEmpty className={styles.heart} />

            <a href="tel:89272770404">
              {" "}
              <BsTelephone className={styles.tel} />
            </a>
            {/* </BsTelephone> */}
          </div>

          {/* <div className={styles.cart}>
            <CartPopup />
          </div> */}
        </div>
        {/* <div className={styles.bottom}>
          <div className={styles.phone}>
            <a href="tel:+79272770404">+7 (927) 277-04-04</a>
          </div>
          <br />
        </div> */}

        {/* <BurgerMenu isActive={isMenuOpen} onClose={closeMenu} /> */}
      </header>
    </>
  );
};

export default Header;
