import React, { useEffect, useState } from "react";
import style from "./formOrderBy.module.scss";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import {
  resetCart,
  selectAllItems,
  selectTotalPrice,
  toggleDeliveryInCart,
} from "../../app/redux/cartStore/cartSlice";
import { createOrder } from "../../app/http/orderApi";
import DeliveryDateTime from "../../features/DeliveryDateTime/DeliveryDateTime";
import NumberFormat, { PatternFormat } from "react-number-format";
import TestRod from "../testRod/TestRod";
import { styled } from "@mui/material/styles";
import {
  selectOrderData,
  selectOrderError,
  selectOrderStatus,
  selectShowPayWidget,
  toggleStatus,
} from "../../app/redux/store/orderSlice/orderSlice";
import PayYooKassa from "../../widgets/payYooKassa/PayYooKassa";
import Modal from "../../shared/ui/modal/Modal";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import YandexDelivery from "../../features/YandexMaps/YandexDelivery";
import Suggest from "../../features/YandexMaps/Suggest";
import { loadTarifFromZone } from "../../app/http/delivery";
import { setPrice } from "../../app/redux/store/deliveryStore/deliverySlice";
import { useNavigate } from "react-router-dom";

const FormOrderBy = () => {
  const orderStatus = useSelector(selectOrderStatus);
  // const showPayWidget = useSelector(selectShowPayWidget); // Используем новый селектор
  const orderError = useSelector(selectOrderError);
  const orderData = useSelector(selectOrderData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allItems = useSelector(selectAllItems);
  const allPrice = useSelector(selectTotalPrice);
  const priceDelivery = useSelector((state) => state.delivery?.price);
  const [deliveryOption, setDeliveryOption] = useState("Самовывоз"); // Default to "Доставка"
  const [priceAndDelivery, setpriceAndDelivery] = useState(null); // Default to "Доставка"
  const [showAddPhone, setShowAddPhone] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comments: "",
    phone: "",
    address: "",
    zone: "",
    deliveryTime: "",
    deliveryOption: "Самовывоз",
    availability: true,
    orderPrice: 0,
    priceDelivery: 0,
    FullOrderPrice: 0,
    variablePay: "",
    addPhone: "",
    anonim: false,
  });
  // const [placemark, setPlacemark] = useState(null);

  // const StyledForm = styled("form")({
  //   display: "flex",
  //   flexWrap: "wrap",
  //   // flexDirection: "column",
  //   gap: "16px",
  // });

  const [modalActive, setModalActive] = useState(false);

  const phoneRegex = /\d/g;
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) || email === "";
  };

  const handleEmailBlur = (event) => {
    const { value } = event.target;
    setIsEmailValid(validateEmail(value));
  };

  const handleCheckboxChangeColor = (event) => {
    setShowAddPhone(event.target.checked);
  };

  // console.log(orderData, "прием платежааааааааааааааааааааааа");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (deliveryOption === "Самовывоз") {
        console.log(formData, "что имеем??????");
        setFormData((prevData) => {
          const updatedFormData = {
            ...prevData,
            orderPrice: allPrice,
            FullOrderPrice: allPrice,
            priceDelivery: 0,
            address: "Мясницкая ул., 54, Саратов",
            variablePay: "Онлайн оплата",
          };
          console.log(updatedFormData, "что имеем после обновления??????");
          dispatch(createOrder({ order: updatedFormData, allItems }));
          return updatedFormData;
        });
        setModalActive(true);
      } else {
        setFormData((prevData) => {
          const updatedFormData = {
            ...prevData,
            FullOrderPrice: +prevData.orderPrice + +priceDelivery,
            variablePay: "Онлайн оплата",
          };
          console.log(updatedFormData, "что имеем после обновления??????");
          dispatch(createOrder({ order: updatedFormData, allItems }));
          return updatedFormData;
        });
        setModalActive(true);
      }

      // dispatch(createOrder({ order: formData, allItems, allPrice }));
      // setModalActive(true);
      // Очистите форму
      //   setFormData({
      //     name: "",
      //     email: "",
      //     message: "",
      //     phone: "",
      //     address: "",
      //     zone: "",
      // deliveryTime: "",
      // deliveryOption: "Самовывоз",
      // availability: true,
      // orderPrice: 0,
      // priceDelivery: 0,
      // FullOrderPrice: 0,
      //   });
      // alert("Заявка успешно отправлена");
    } catch (error) {
      // Обработка ошибок
    }
  };
  const handleSubmitPostPay = async (e) => {
    e.preventDefault();

    try {
      setFormData((prevData) => {
        const updatedFormData = {
          ...prevData,
          orderPrice: allPrice,
          FullOrderPrice: allPrice,
          priceDelivery: 0,
          address: "Мясницкая ул., 54, Саратов",
          variablePay: "Оплата на месте",
        };
        dispatch(createOrder({ order: updatedFormData, allItems }));
        dispatch(toggleStatus({ statusInfo: "постоплата" }));

        return updatedFormData;
      });

      // dispatch(createOrder({ order: formData, allItems, allPrice }));
      // setModalActive(true);
      // Очистите форму
      //   setFormData({
      //     name: "",
      //     email: "",
      //     message: "",
      //     phone: "",
      //     address: "",
      //     zone: "",
      // deliveryTime: "",
      // deliveryOption: "Самовывоз",
      // availability: true,
      // orderPrice: 0,
      // priceDelivery: 0,
      // FullOrderPrice: 0,
      //   });
      // alert("Заявка успешно отправлена");
    } catch (error) {
      // Обработка ошибок
    }
  };

  // const handleCreateOrder = () => {
  //   dispatch(createOrder({ order: formData, allItems, allPrice }));
  // };

  const handleTimeChange = (selectedDate) => {
    // console.log(selectedDate, "что со временем?");
    setFormData((prevData) => ({
      ...prevData,
      deliveryTime: selectedDate,
    }));
    if (formData.address !== "Мясницкая ул., 54, Саратов") {
      dispatch(loadTarifFromZone({ zone: formData.zone, time: selectedDate }));
    }
  };

  const handleChange = (e) => {
    const { name, checked, value } = e.target; // Добавляем checked для обработки состояния чекбокса
    if (name === "anonim") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked, // Переключаем значение между true и false в зависимости от состояния чекбокса
      }));
    } else if (name === "phone") {
      const matches = value.match(phoneRegex);
      const numericValue = matches ? matches.join("") : ""; // Проверяем на null
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else {
      // Обычное обновление состояния для других полей
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDeliveryOptionChange = (option) => {
    setDeliveryOption(option);
    setFormData((prevData) => ({
      ...prevData,
      deliveryOption: option,
    }));
    // dispatch(toggleDeliveryInCart(option));
  };

  const handleZoneChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      address: event.address,
      zone: event.zone,
      availability: event.availability,
    }));
    // console.log(event.zone, "что с зоной доставки???");
    // if (event.zone === "Доставка не осуществляется") {
    //   dispatch(setPrice("Доставка не осуществляется"));
    // } else {
    if (event.availability) {
      dispatch(
        loadTarifFromZone({
          zone: event.zone,
          time: formData.deliveryTime,
          availability: event.availability,
        })
      );
    }

    // }
  };

  // const isNameValid = formData && formData?.name && formData?.name?.length >= 2;
  // const isPhoneValid =
  //   formData && formData?.phone && formData?.phone?.length === 12;

  const isPhoneValid =
    formData?.phone && formData.phone.replace(/\D/g, "").length === 10;

  useEffect(() => {
    if (priceDelivery) {
      setpriceAndDelivery(+priceDelivery + +allPrice);
      setFormData((prevData) => ({
        ...prevData,
        priceDelivery: priceDelivery,
      }));
    }
  }, [priceDelivery, deliveryOption, allPrice]);
  useEffect(() => {
    if (orderStatus === "постоплата" || orderStatus === "оплачено") {
      navigate("/confirm");
      dispatch(resetCart());
    }
  }, [orderStatus]);

  useEffect(() => {
    if (allPrice) {
      setpriceAndDelivery(allPrice);
      setFormData((prevData) => ({
        ...prevData,
        orderPrice: allPrice,
        FullOrderPrice: allPrice,
        address: "Мясницкая ул., 54, Саратов",
      }));
    }
  }, []);

  return (
    <>
      {/* <div className={style.backwrap}></div> */}

      <div className={style.wrapper}>
        {orderStatus === "pending" && orderData ? (
          // <PayYooKassa data={orderData} onCreateOrder={handleCreateOrder} />
          <Modal visible={modalActive} setVisible={setModalActive}>
            <PayYooKassa data={orderData} />
          </Modal>
        ) : null}

        {/* <StyledForm> */}
        <Box
          component="form"
          // my={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "auto",
            flexWrap: "wrap",
            alignItems: "center",
            // padding: "0.5rem",
            justifyContent: "center",
            "& .MuiTextField-root": {
              m: 0.5,
              width: "58ch",
              "@media (max-width: 768px)": {
                width: "30ch", // Change width for screens up to 768px
              },
            },
          }}
          noValidate
          autoComplete="off">
          <div className={style.dostavka}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl>
                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group">
                  {/* <FormControlLabel
              value="disabled"
              disabled
              control={<Radio />}
              label="other"
            /> */}
                  <FormControlLabel
                    // value="female"
                    name="deliveryOption"
                    value="Самовывоз"
                    checked={deliveryOption === "Самовывоз"}
                    onChange={() => handleDeliveryOptionChange("Самовывоз")}
                    control={<Radio />}
                    label="Самовывоз"
                  />
                  <FormControlLabel
                    type="radio"
                    name="deliveryOption"
                    value="Доставка"
                    checked={deliveryOption === "Доставка"}
                    onChange={() => handleDeliveryOptionChange("Доставка")}
                    control={<Radio />}
                    label="Доставка"
                  />
                  {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                </RadioGroup>
              </FormControl>

              <DeliveryDateTime
                deliveryOption={deliveryOption}
                onTimeChange={handleTimeChange}
              />
            </div>

            <div style={{ position: "relative", minHeight: "20px" }}>
              {deliveryOption === "Самовывоз" ? (
                <>
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    Адрес самовывоза:{" "}
                    <span
                      style={{ color: "green", textDecoration: "underline" }}>
                      Мясницкая ул., 54, Саратов
                    </span>{" "}
                  </div>
                  <p>Самовывоз: бесплатно</p>
                </>
              ) : deliveryOption === "Доставка" &&
                formData.address === "Мясницкая ул., 54, Саратов" ? (
                <>
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    Выбран адрес:{" "}
                    <span
                      style={{ color: "green", textDecoration: "underline" }}>
                      введите адрес на карте
                    </span>{" "}
                  </div>
                  <p> Стоимость доставки :</p>
                </>
              ) : (
                <>
                  <div style={{ fontSize: "14px" }}>
                    {" "}
                    Выбран адрес:{" "}
                    <span
                      style={{ color: "green", textDecoration: "underline" }}>
                      {formData.address}
                    </span>{" "}
                  </div>

                  {formData && !formData.availability ? (
                    <p className={style.error}>
                      доставку необходимо согласовать с менеджером или
                      попробуйте другой адрес
                    </p>
                  ) : (
                    <p>
                      {" "}
                      Стоимость доставки :{" "}
                      <span
                        style={{
                          color: "green",
                          // textDecoration: "underline",
                          fontWeight: "bold",
                          // padding: "0",
                        }}>
                        {priceDelivery}
                      </span>{" "}
                      руб{" "}
                    </p>
                  )}
                </>
              )}
            </div>
            <p style={{ margin: "0" }}>
              {" "}
              Итого к оплате :{" "}
              <span
                style={{
                  color: "green",
                  // textDecoration: "underline",
                  fontWeight: "bold",
                  // padding: "0",
                }}>
                {deliveryOption === "Самовывоз" ? allPrice : priceAndDelivery}
              </span>{" "}
              руб{" "}
            </p>
            {!formData.availability && deliveryOption !== "Самовывоз" ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                связаться с менеджером
              </Button>
            ) : deliveryOption !== "Самовывоз" ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  formData.address === "Мясницкая ул., 54, Саратов" ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                Оплатить
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                Оплатить
              </Button>
            )}
            {deliveryOption === "Самовывоз" && (
              <Button
                variant="contained"
                color="success"
                type="submit"
                onClick={handleSubmitPostPay}
                disabled={
                  formData.deliveryTime === "" ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  (formData.address === "" &&
                    formData.deliveryOption === "Доставка")
                }>
                Оплата при получении
              </Button>
            )}
          </div>

          <TextField
            label="Имя"
            type="text"
            name="name"
            value={formData.name}
            onInput={handleChange}
            // error={!isNameValid}
            // helperText={!isNameValid && "Введите имя (минимум 2 символа)"}
          />

          <TextField
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!isEmailValid}
            onBlur={handleEmailBlur}
            helperText={!isEmailValid && "Введите корректный email"}
          />

          <TextField
            id="outlined-multiline-static"
            label="Открытка"
            minRows={3}
            name="comments"
            placeholder="Тут можно написать поздравление и пожелания"
            multiline
            // rows={4}
            value={formData.comments}
            onChange={handleChange}
          />
          <PatternFormat
            format="+7 (###) ###-##-##"
            mask="_"
            type="text"
            name="phone"
            value={formData.phone}
            placeholder="+7 (999) 111-22-22"
            customInput={TextField}
            onValueChange={({ value }) =>
              handleChange({ target: { name: "phone", value } })
            }
            label="Телефон"
            error={!isPhoneValid}
            helperText={!isPhoneValid && "Введите корректный номер телефона"}
          />
          {/* <InputMask
            mask="7 (999) 999-99-99"
            maskChar="_"
            type="text"
            name="phone"
            value={formData.phone}
            placeholder="+799911112222"
            onChange={handleChange}>
            {(inputProps) => (
              <TextField
                {...inputProps}
                label="Телефон"
                error={!isPhoneValid}
                helperText={
                  !isPhoneValid && "Введите корректный номер телефона"
                }
              />
            )} */}
          {/* </InputMask> */}
          <FormControlLabel
            control={
              <Checkbox
                // defaultChecked
                color="secondary"
                onChange={handleCheckboxChangeColor}
              />
            }
            label="Получатель другой человек"
          />
          {showAddPhone && (
            // <InputMask
            //   mask="7 (999) 999-99-99"
            //   maskChar="_"
            //   type="text"
            //   name="addPhone"
            //   value={formData.addPhone}
            //   placeholder="+799911112222"
            //   onChange={handleChange}>
            //   {(inputProps) => (
            //     <TextField
            //       {...inputProps}
            //       label="Телефон другого человека"
            //       error={!isPhoneValid}
            //       helperText={
            //         !isPhoneValid && "Введите корректный номер телефона"
            //       }
            //     />
            //   )}
            // </InputMask>
            <PatternFormat
              format="+7 (###) ###-##-##"
              allowEmptyFormatting
              mask="_"
              type="text"
              name="addPhone"
              value={formData.addPhone}
              placeholder="+7 (999) 111-22-22"
              customInput={TextField}
              onValueChange={(values) =>
                handleChange({
                  target: { name: "addPhone", value: values.formattedValue },
                })
              }
              label="Телефон другого человека"
              error={!isPhoneValid}
              helperText={!isPhoneValid && "Введите корректный номер телефона"}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                // defaultChecked
                color="secondary"
                name="anonim"
                onChange={handleChange}
              />
            }
            label="Отправить анонимно"
          />

          {/* {deliveryOption === "Доставка" && (
            // <TextField
            //   label="Адрес доставки"
            //   type="text"
            //   name="address"
            //   value={formData.address}
            //   onChange={handleChange}
            //   error={!isAddressValid}
            //   helperText={
            //     !isAddressValid && "Введите адрес (минимум 5 символов)"
            //   }
            // />
            <Suggest />
          )} */}

          {/* <DeliveryDateTime
            deliveryOption={deliveryOption}
            onTimeChange={handleTimeChange}
          /> */}
        </Box>
        {deliveryOption === "Самовывоз" ? (
          <YandexDelivery
            placemark={deliveryOption}
            addressCurrent={() => {}}
          />
        ) : (
          <YandexDelivery
            delivery={"доставка"}
            addressCurrent={handleZoneChange}
            time={formData.deliveryTime === "" ? null : formData.deliveryTime}
          />
        )}
      </div>
    </>
  );
};

export default FormOrderBy;
