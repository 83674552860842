// CardGalleryMobile.js
import React, { useState, useEffect } from "react";
import styles from "./cardGalleryMobile.module.scss";

const CardGalleryMobile = ({ images }) => {
  const [imageUrl, setImageUrl] = useState("");
  //   const [currentIndex, setCurrentIndex] = useState(0);

  //   const handleMouseOver = (index) => {
  //     setImageUrl(
  //       process.env.REACT_APP_API_URL +
  //         "images/products/" +
  //         images[index]?.mobileFull
  //     );
  //     setCurrentIndex(index);
  //   };

  //   const handleMouseOut = () => {
  //     setImageUrl(
  //       process.env.REACT_APP_API_URL + "images/products/" + images[0]?.mobileFull
  //     );
  //     setCurrentIndex(0);
  //   };

  useEffect(() => {
    if (images && images.length > 0) {
      setImageUrl(
        process.env.REACT_APP_API_URL +
          "images/products/" +
          images[0]?.desktopCard
      );
    }
  }, [images]);

  return (
    // <div
    //   className={styles.img}
    //   style={{
    //     backgroundImage: `url(${imageUrl})`,
    //     backgroundSize: "cover",
    //     height: "100%",
    //   }}>
    //   {images &&
    //     images.length > 0 &&
    //     images.map((image, index) => (
    //       <div
    //         key={index}
    //         style={{
    //           width: `${100 / images.length}%`,
    //           height: "200px",
    //           opacity: "100%",
    //         }}
    //         onMouseOver={() => handleMouseOver(index)}
    //         onMouseOut={handleMouseOut}
    //       />
    //     ))}
    // </div>
    // <div >
    <img className={styles.wrap} src={imageUrl} alt="фото" />
    // </div>
  );
};

export default CardGalleryMobile;
